@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '@fortawesome/fontawesome-free/css/all.min.css';

// @import "~styles/fonts/Aeonik/stylesheet.css";
// @import "~styles/fonts/Tungsten/stylesheet.css";

// $fontAeonik: Aeonik;
// $fontAeonikLight: Aeonik;
// $fontTungsten: Tungsten;
// $fontTungstenBook: "Tungsten Book";

@mixin font($fontFamily, $fontSize, $lineHeight, $fontWeight: normal, $fontStyle: normal) {
  font-family: $fontFamily;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

// body {
//   font-family: Montserrat, "Open Sans", "Helvetica Neue", sans-serif !important;
// }

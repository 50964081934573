@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/utilities';

$primary: #097988;
$success: green;

// Theme
$custom-theme-colors: map-merge(
  $theme-colors,
  (
    'primary': #097988,
    'primary-rgb': rgb(9, 121, 136),
    'success': green,
    'info': #00d2ff,
    'warning': #ffc107,
    'danger': lightcoral,
  )
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary !important;
}

.dropdown-menu.show {
  right: 0;
  padding: 0;
  overflow: hidden;
}

.dropdown-divider {
  margin: 0 !important;
}

.dropdown-item {
  padding: 0.5rem 1rem !important;
}

@import '~bootstrap/scss/bootstrap';

.navbar-nav {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0 20px;
  margin: 0;
  margin-left: 20px;
}

.navbar-dark .main-color.navbar-toggler {
  background-color: #227680;
}

.navbar-dark .navbar-nav a.nav-link:not(.dropdown-toggle) {
  color: #227680;
  font-size: 1.125rem;
  line-height: 1;
  display: block;
  margin: 12px 0;
  position: relative;

  &:visited {
    color: #227680;
    margin: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #0c7888;
    transition: 0.2s all ease;
    transform: translateX(50%);
    width: 0%;
    opacity: 0;
  }

  &:hover,
  &.active {
    &::after {
      transform: translateX(0);
      width: 100%;
      opacity: 1;
    }
  }
}

#collasible-nav-dropdown {
  color: #007987;
}

.loginContainer {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: #fff;
  border-radius: 10px;
  padding: 25px 45px;
  position: relative;
  z-index: 2;
  margin-bottom: 150px;

  .title {
    font-size: 40px;
    margin-bottom: 10px;
    color: #007987;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .subtitle {
    color: #777;
    margin-bottom: 15px;
  }
}

.auth-background {
  height: 100%;
  display: flex;
}

.auth-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f9f9f9;
}

.form-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: calc(100% - 100px);
}

@import './theme';

.head {
  background: map-get($theme-colors, 'primary');
  color: #fff;

  overflow: hidden;

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
  }
}
